/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "stride.stakeibc";

export interface Validator {
  name: string;
  address: string;
  weight: string;
  delegation: string;
  slashQueryProgressTracker: string;
  slashQueryCheckpoint: string;
  sharesToTokensRate: string;
  delegationChangesInProgress: string;
  slashQueryInProgress: boolean;
}

function createBaseValidator(): Validator {
  return {
    name: "",
    address: "",
    weight: "0",
    delegation: "",
    slashQueryProgressTracker: "",
    slashQueryCheckpoint: "",
    sharesToTokensRate: "",
    delegationChangesInProgress: "0",
    slashQueryInProgress: false,
  };
}

export const Validator = {
  encode(
    message: Validator,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.address !== "") {
      writer.uint32(18).string(message.address);
    }
    if (message.weight !== "0") {
      writer.uint32(48).uint64(message.weight);
    }
    if (message.delegation !== "") {
      writer.uint32(42).string(message.delegation);
    }
    if (message.slashQueryProgressTracker !== "") {
      writer.uint32(74).string(message.slashQueryProgressTracker);
    }
    if (message.slashQueryCheckpoint !== "") {
      writer.uint32(98).string(message.slashQueryCheckpoint);
    }
    if (message.sharesToTokensRate !== "") {
      writer.uint32(82).string(message.sharesToTokensRate);
    }
    if (message.delegationChangesInProgress !== "0") {
      writer.uint32(88).int64(message.delegationChangesInProgress);
    }
    if (message.slashQueryInProgress === true) {
      writer.uint32(104).bool(message.slashQueryInProgress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Validator {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValidator();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.address = reader.string();
          break;
        case 6:
          message.weight = longToString(reader.uint64() as Long);
          break;
        case 5:
          message.delegation = reader.string();
          break;
        case 9:
          message.slashQueryProgressTracker = reader.string();
          break;
        case 12:
          message.slashQueryCheckpoint = reader.string();
          break;
        case 10:
          message.sharesToTokensRate = reader.string();
          break;
        case 11:
          message.delegationChangesInProgress = longToString(
            reader.int64() as Long
          );
          break;
        case 13:
          message.slashQueryInProgress = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Validator {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      address: isSet(object.address) ? String(object.address) : "",
      weight: isSet(object.weight) ? String(object.weight) : "0",
      delegation: isSet(object.delegation) ? String(object.delegation) : "",
      slashQueryProgressTracker: isSet(object.slashQueryProgressTracker)
        ? String(object.slashQueryProgressTracker)
        : "",
      slashQueryCheckpoint: isSet(object.slashQueryCheckpoint)
        ? String(object.slashQueryCheckpoint)
        : "",
      sharesToTokensRate: isSet(object.sharesToTokensRate)
        ? String(object.sharesToTokensRate)
        : "",
      delegationChangesInProgress: isSet(object.delegationChangesInProgress)
        ? String(object.delegationChangesInProgress)
        : "0",
      slashQueryInProgress: isSet(object.slashQueryInProgress)
        ? Boolean(object.slashQueryInProgress)
        : false,
    };
  },

  toJSON(message: Validator): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.address !== undefined && (obj.address = message.address);
    message.weight !== undefined && (obj.weight = message.weight);
    message.delegation !== undefined && (obj.delegation = message.delegation);
    message.slashQueryProgressTracker !== undefined &&
      (obj.slashQueryProgressTracker = message.slashQueryProgressTracker);
    message.slashQueryCheckpoint !== undefined &&
      (obj.slashQueryCheckpoint = message.slashQueryCheckpoint);
    message.sharesToTokensRate !== undefined &&
      (obj.sharesToTokensRate = message.sharesToTokensRate);
    message.delegationChangesInProgress !== undefined &&
      (obj.delegationChangesInProgress = message.delegationChangesInProgress);
    message.slashQueryInProgress !== undefined &&
      (obj.slashQueryInProgress = message.slashQueryInProgress);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Validator>, I>>(
    object: I
  ): Validator {
    const message = createBaseValidator();
    message.name = object.name ?? "";
    message.address = object.address ?? "";
    message.weight = object.weight ?? "0";
    message.delegation = object.delegation ?? "";
    message.slashQueryProgressTracker = object.slashQueryProgressTracker ?? "";
    message.slashQueryCheckpoint = object.slashQueryCheckpoint ?? "";
    message.sharesToTokensRate = object.sharesToTokensRate ?? "";
    message.delegationChangesInProgress =
      object.delegationChangesInProgress ?? "0";
    message.slashQueryInProgress = object.slashQueryInProgress ?? false;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
